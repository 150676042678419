import wAxios from '@/plugins/w/axios'

export default {
    namespaced: true,
    state: {
        status: '',
        _token: localStorage.getItem('wzo_vue_user_token') || '',
        user: JSON.parse(localStorage.getItem('wzo_vue_user_user') || 'null'),
    },
    mutations: {
        auth_request(state: any) {
            state.status = 'loading'
        },
        auth_success(state: any, data: any) {
            state.status = 'success'
            if (data.token) {
                state._token = data.token
                localStorage.setItem('hemisfera_jwt', data.token)
                localStorage.setItem('wzo_vue_user_token', data.token)
            }

            if (data.user) {
                state.user = data.user
                localStorage.setItem('wzo_vue_user_user', JSON.stringify(data.user))
            }
        },
        auth_error(state: any) {
            state.status = 'error'
            state._token = ''
            localStorage.removeItem('wzo_vue_user_token')
            localStorage.removeItem('wzo_vue_user_user')
        },
        logout(state: any) {
            state.status = ''
            state._token = ''
            state.user = null
            localStorage.clear()
            localStorage.setItem('isMoxios', 'false')
            localStorage.removeItem('hemisfera_orderId')
        }
    },
    actions: {
        login({commit}: {commit: any}, credentials: any) {
            return new Promise((resolve, reject) => {
                commit('auth_request')

                wAxios.post_data('v1/auth/login', credentials)
                    .then((response: any) => {
                        const { data: { response: { token, user }}} = response
                        commit('auth_success', { token, user })
                        resolve(response)
                    }).catch(error => {
                        commit('auth_error')
                        reject(error.response.data)
                    })
            })
        },
        signup({commit }: {commit: any}, credentials: any) {
            return new Promise((resolve, reject) => {
                commit('auth_request')
                wAxios.post_data('v1/auth/signup', credentials)
                    .then((response: any) => {
                        const { data: { response: { token, user }}} = response
                        commit('auth_success', { token, user })
                        resolve(response)
                    }).catch(error => {
                        commit('auth_error')
                        reject(error.response.data)
                    })
            })
        },
        logout({commit}: {commit: any}) {
            return new Promise((resolve) => {
                wAxios.post_auth('v1/auth/invalidate')
                    .finally(() => {
                        commit('logout')
                        resolve(null)
                    })
            })
        },
        forgotPassword(state, credentials: any) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth('v1/auth/forgot', credentials)
                    .then(response => resolve(response))
                    .catch(error => reject(error.data))
            })
        },
        resetPassword(state, credentials: any) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth('v1/auth/reset', credentials)
                    .then(response => resolve(response))
                    .catch(error => reject(error.data))
            })
        },
        async refreshToken({commit}: {commit: any}) {
            try {
                const {
                    data: { response: { token, user }}
                } = await wAxios.post_auth_data('v1/auth/refresh')
                commit('auth_success', { token, user })
                return token
            } catch (error) {
                commit('auth_error')
                throw error.response.data
            }
        },
        userInfo({commit}: {commit: any}) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/auth/info')
                    .then((response: any) => {
                        const user = response.data.response.user
                        commit('auth_success', { user })
                        resolve(user)
                    }).catch(error => {
                        reject(error.response)
                    })
            })
        },
        updateUserInfo({commit}: {commit: any}, data: any) {
            return new Promise((resolve, reject) => {
                wAxios.post_auth_data('v1/auth/info', data)
                    .then((response: any) => {
                        const user = response.data.response.user
                        commit('auth_success', { user })
                        resolve(user)
                    }).catch(error => {
                        reject(error.response.data)
                    })
            })
        },
    },
    getters: {
        isLoggedIn: (state: any) =>  {
            return !!state._token
        },
        authStatus: (state: any) => {
            return state.status
        },
        user: (state: any) => {
            return state.user
        },
        token: (state: any) => {
            return state._token

        }
    }
}
