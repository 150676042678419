import wAxios from '@/plugins/w/axios'
import axios from 'axios'
import { getField, updateField } from 'vuex-map-fields'
import { mergeOrderRegistrations } from './order.util'

const resetStates = (state) => {
  state.orderRegistrations = [{
    order_registration_dates: [],
    student_id: null,
    student: {},
    product_period: {},
    product_period_id: null,
    faculty_id: null,
    fe_custom_data: { student_index: Math.floor(Math.random() * 1000000000000), preferred_days: [] }
  }]
  state.prices = []
  state.coupon = null
  state.consent = false
  state.orderConsent = false
  state.orderPrice = {}
  state.isGiftVoucher = false
  state.isPartialPayment = null
  state.partialPayments = null
  state.note = null,

  localStorage.removeItem('hemisfera_coupon_discount')
  localStorage.removeItem('hemisfera_preregEmail')
  localStorage.removeItem('checkoutUrl')
}

export default {
  namespaced: true,

  state: {
    orderRegistrations: [
      {
        order_registration_dates: [],
        student_id: null,
        student: {},
        product_period: {
          place: {},
          product: null,
          available_dates: [],
          occupied_dates: []
        },
        product_period_id: null,
        faculty_id: null,
        fe_custom_data: { student_index: Math.floor(Math.random() * 1000000000000), preferred_days: [] }
      }
    ],
    orderId: null,
    products: null,
    prices: [],
    coupon: null,
    note: null,
    consent: false,
    orderConsent: false,
    isPartialPayment: null,
    partialPayments: null,
    isGiftVoucher: false,
    orderPrice: {},
    lastOrderId: localStorage.getItem('hemisfera_orderId'),
    priceLoading: false,
  },

  getters: {
    // returns true if validation hasn't passed
    totalDaysValidation(state) {
      // if product or period is not selected
      if (state.orderRegistrations.some(or => !or.product_period_id))
        return true

      // every student have to have minimal number of days selected
      return state.orderRegistrations.some(or => {
        // if days selection is not allowed then the validation does not make a sense
        if (!or.product_period.is_allowed_select_day)
          return false
        return or.product_period.product.minimum_selected_days > or.order_registration_dates.length
      })
    },

    orderProductType(state) {
      /*
        Currently, the application does not allow to have more product types selected in one order.
        If this functionality will be possible in the future, this logic will be wrong.
      */
      if (!state.orderRegistrations[0].product_period.product)
        return

      return state.orderRegistrations[0].product_period.product.product_type.code
    },

    facultySelectionValidation(state) {
      // if faculties are present on product period object, then some of them have to be selected
      return state.orderRegistrations.some(or => {
        return or.product_period.faculties && or.product_period.faculties.length && !or.faculty_id
      })
    },

    productForOrderRegistration: state => index => {
      if (!state.orderRegistrations[index].product_period)
        return null
      return state.orderRegistrations[index].product_period.product
    },

    orderRegistrationsForStudentIndex: state => studentIndex => {
      return [...state.orderRegistrations
        .filter(or => or.fe_custom_data.student_index == studentIndex)]
    },

    orderRegistrationsForStudentIndexWithPrices: state => studentIndex => {
      return [...state.orderPrice.order_once_pay.order_registrations
        .filter(or => or.product_period_id && or.fe_custom_data.student_index == studentIndex)]
    },

    calendarOrderRegistration: (state, getters) => (studentIndex, type) => {
      const orderRegistrations = getters.orderRegistrationsForStudentIndex(studentIndex, type)
      return mergeOrderRegistrations(orderRegistrations, type)
    },

    isPaymentSelected(state) {
      return state.isPartialPayment !== null
    },

    orderDataApiObject(state) {
      return  {
        order_registrations: state.orderRegistrations,
        is_partial_payment: state.isPartialPayment,
        partial_payments: state.partialPayments || 2,
        coupon: state.coupon ? state.coupon.coupon_code : null,
        note: state.note,
      }
    },

    getField
  },

  mutations: {
    orderUpdated(state, order) {
      state.orderRegistrations = order.order_registrations
      state.coupon = order.coupon
      state.isPartialPayment = order.is_partial_payment
      state.orderId = order.id
    },

    productsChanged(state, products) {
      state.products = products
    },

    pricesRetrieved(state, product) {
      let prices = [{ price: Number(product.price), course_count: 0 }]

      if (product.course_discounts)
        prices = prices.concat(product.course_discounts)

      prices.sort((a, b) => b.course_count - a.course_count)
      state.prices = [...prices]
    },

    couponUsed(state, coupon) {
      localStorage.setItem('hemisfera_coupon_discount', JSON.stringify(coupon))
      state.coupon = coupon
    },

    reseted(state, resetOrderId = false) {
      resetStates(state)

      if (resetOrderId)
        localStorage.removeItem('hemisfera_orderId')
    },

    pricesUpdated(state, orderPrice) {
      if (!state.partialPayments || state.partialPayments > orderPrice.order_monthly_pay.max_partial_payments) {
        state.partialPayments = orderPrice.order_monthly_pay.max_partial_payments
      }
      state.orderPrice = orderPrice
    },

    lastOrderId(state, orderId) {
      state.lastOrderId = orderId
      localStorage.setItem('hemisfera_orderId', orderId)
    },

    resetedStudent(state, studentIndex) {
      // find index of student that needs to be reseted
      const index = state.orderRegistrations.findIndex(or => or.fe_custom_data.student_index == studentIndex)

      state.partialPayments = null
      state.orderId = null
      state.orderRegistrations = state.orderRegistrations.filter(or => or.fe_custom_data.student_index != studentIndex)
      state.orderRegistrations.splice(index, 0, {
        order_registration_dates: [],
        student_id: null,
        student: {},
        product_period: {},
        product_period_id: null,
        faculty_id: null,
        fe_custom_data: { student_index: studentIndex, preferred_days: [] }
      })
    },

    updateField
  },

  actions: {
    async getPrices(state, payload: any) {
      if (globalThis.hemisfera_paymentsCancelToken)
        globalThis.hemisfera_paymentsCancelToken.cancel('Payment request canceled.')
      globalThis.hemisfera_paymentsCancelToken = axios.CancelToken.source()

      return wAxios.post('v1/orders/payments', payload, { cancelToken: globalThis.hemisfera_paymentsCancelToken.token })
        .then((response: any) => {
          globalThis.hemisfera_paymentsCancelToken = null
          return response.data
        })
    },

    async createOrder({commit, state}, payload = {}) {
      const data: any = {
        order_registrations: state.orderRegistrations,
        is_partial_payment: state.isPartialPayment,
        partial_payments: state.partialPayments || 2,
        gift_voucher: state.isGiftVoucher,
        coupon: state.coupon ? state.coupon.coupon_code : null,
        ...payload
      }

      return wAxios.post_auth('v1/orders', data)
        .then((response: any) => {
          commit('lastOrderId', response.data.data.id)
          return response.data
        }).catch(error => alert(error))
    },

    async getOrder({commit, state}) {
      if (!state.lastOrderId)
        return

      return wAxios.get_auth_data(`v1/orders/${state.lastOrderId}`)
        .then((response: any) => {
          commit('orderUpdated', response)
          commit('pricesRetrieved', response.order_registrations[0].product_period.product)
          return response
        }).catch(error => console.error(error))
    },

    async attachOrderToUser() {
      return wAxios.put_auth(`v1/orders/${localStorage.getItem('hemisfera_orderId')}/attach`, null)
        .then((response: any) => response)
        .catch(error => alert(error))
    },

    async updateOrder({state, getters}, data: any) {
      const apiData = getters.orderDataApiObject
      data = {...apiData, ...data}

      const orderId = state.lastOrderId

      return wAxios.put_auth(`v1/orders/${orderId}`, data)
    },

    async useCoupon({ state, commit, dispatch, getters }: { state: any; commit: any; dispatch: any; getters: any }, coupon_code) {
      state.priceLoading = true
      return wAxios.post('v1/coupons', {
        coupon_code: coupon_code,
        product_type: getters.orderProductType
      } )
        .then((response: any) => {
          if (!response.data)
            return false
          commit('couponUsed', response.data.data)
          return response.data
        })
        .catch(error => {
          console.error(error)
          throw error
        }).finally(() => {
          state.priceLoading = false
          dispatch('getNewPrices')
        })
    },

    async getNewPrices({ state, commit, dispatch }, pricesData?) {
      if (!state.orderId && !state.partialPayments) {
        const initPayload: any = {
          order_registrations: (pricesData && pricesData.data) || state.orderRegistrations,
          coupon: state.coupon ? state.coupon.coupon_code : null,
          partial_payments: 2
        }
        const initData = await dispatch('getPrices', initPayload)
        state.partialPayments = initData.data.order_monthly_pay.max_partial_payments
      }
      state.priceLoading = true
      const payload: any = {
        order_registrations: (pricesData && pricesData.data) || state.orderRegistrations,
        coupon: state.coupon ? state.coupon.coupon_code : null,
        partial_payments: state.partialPayments || 2,
        order_id: state.orderId ?? null
      }

      // remove fields from payload, which should not be there
      payload.order_registrations = [...payload.order_registrations].map(or => {
        or = {...or}
        const props = [
          'id', 'student_id', 'price', 'total_price',
          'lecture_price', 'student', 'product_period', 'partial_payment_fee'
        ]
        props.forEach(prop => delete or[prop])
        return or
      })

      const res = await dispatch('getPrices', payload)
      commit('pricesUpdated', res.data)
      state.priceLoading = false
    }
  }
}
