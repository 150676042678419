import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import isNotLoggedInGuard from '@/plugins/w/authStore/notLoggedIn.guard'

import VueToast from 'vue-toast-notification'
Vue.use(VueToast)

Vue.use(VueRouter)

const wRoute = (name: string, path: string, component, meta?, beforeEnter?) => ({name, path, component, meta, beforeEnter})
const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/plugins/app/_layout/default.vue'),
    children: [
      wRoute('ProductTypeSelect',  '',                        () => import('@/plugins/app/product-type-select/product-type-select.vue'), {step: 1}),
      wRoute('Order',              '/:type',                  () => import('@/plugins/app/order/order.vue'), {step: 1}),
      wRoute('Summary',            '/objednavka/zhrnutie',    () => import('@/plugins/app/summary/summary-view.vue'), {step: 4}),
      wRoute('Acknowledgment',     '/objednavka/dakujeme',    () => import('@/plugins/app/summary/acknowledgment.vue'), {step: 5}),
      wRoute('Login',              '/ucet/prihlasenie',       () => import('@/plugins/app@user/login/login.vue'), {step: 2}, isNotLoggedInGuard),
      wRoute('Registration',       '/ucet/registracia',       () => import('@/plugins/app@user/register/registration.vue'), {step: 2}, isNotLoggedInGuard),
      wRoute('ForgotPassword',     '/ucet/zabudnute-heslo',   () => import('@/plugins/app@user/forgot-pwd/forgot-password.vue'), {step: 2}, isNotLoggedInGuard),
      wRoute('ResetPassword',      '/ucet/reset-hesla',       () => import('@/plugins/app@user/forgot-pwd/reset-password.vue'), {step: 2}, isNotLoggedInGuard),
      wRoute('Settings',           '/ucet/nastavenie',        () => import('@/plugins/app@user/settings/settings.vue')),
      wRoute('StudentsInformation','/ucet/udaje-o-studentoch', () => import('@/plugins/app@user/register/students-information.vue'), {step: 3})
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router

router.beforeEach((to, from, next) => {  
  if (to !== from) {
    Vue.$toast.clear()
  }
  next()
})