import Vue from 'vue'
import VueRouter from 'vue-router'

import VueAnalytics from 'vue-analytics'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueGtm from "vue-gtm"

export default {
    addGoogleAnalytics(router: VueRouter, config=[]) {
        if (process.env.VUE_APP_GOOGLE_ANALYTICS_ID)
            Vue.use(VueAnalytics, {
                id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
                router,
                set: config
        })
    },
    addFacebookPixel(router: VueRouter) {
        if (process.env.VUE_APP_FACEBOOK_PIXEL_ID) {
           Vue.use(VueFacebookPixel, {
               id: process.env.VUE_APP_FACEBOOK_PIXEL_ID,
               router
            })
        }
    },
    addGoogleTagManager(router: VueRouter) {
        if (process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID)
            Vue.use(VueGtm, {
                id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
                vueRouter: router
            })
        }
    }